@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.App {
  text-align: center;
  font-family: poppins, sans-serif;
 
}
/* @media (max-width: 768px) {
  .App {
    min-width: auto;
  }
} */
*, :after, :before {
  box-sizing: border-box;
}
h1,.h1{font-size: 46px;line-height: 1.2;font-weight: 600;margin: 0;}
h2,.h2{font-size: 36px;line-height: 1.2;font-weight: 600;margin: 0;}
h3,.h3{font-size: 28px;line-height: 1.2;font-weight: 600;margin: 0;}
h4,.h4{font-size: 24px;line-height: 1.2;font-weight: 600;margin: 0;}
h5,.h5{font-size: 20px;line-height: 1.2;font-weight: 600;margin: 0;}
h6,.h6{font-size: 16px;line-height: 1.2;font-weight: 600;margin: 0;}

.slick-slider .slick-next{
  right: 0;
  z-index: 1;
  width: 32px;
  height: 32px;
}
.slick-slider .slick-prev{
  left: 0;
  z-index: 1;
  width: 32px;
  height: 32px;
}
.slick-slider .slick-prev:before, .slick-slider .slick-next:before{
  font-size: 32px;
  opacity: .65;
  color: #fa741b;
}
.Nav{
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 6px 16px;
  height: 60px;
  background-color: #f5f5f5e1;
  z-index: 99;
  top: 0;
  left: 0;
  right: 0;
}
.Nav a {
  color: inherit;
  text-decoration: none;
}
.toggleMenu{
  display: none;
}
.Hide{
  display: none !important;
  transition: all .2s ease-in-out;
}
.NavIcon {
    margin-left: .25rem !important;
    box-sizing: border-box;
    font-family: Poppins,sans-serif;
    font-size: 33px;
    font-weight: 600;
}
.NavIcon svg {
  margin-right: .25rem;
  vertical-align: middle;
}
.NavLinks {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: calc(10px + 1vw);
}
.NavLinks a {
  border-bottom: 1px solid transparent;
  color: #646464;
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
  margin: 0 10px 0 0;
  padding: 6px 0;
  display: block;
  text-decoration: none;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
}
.NavLinks a:hover {
  color: #000;
  border-bottom: 1px solid #F6511D;
}

/* .LimitWidthParent{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.LimitWidthChild{
  max-width: 2400px;
}  */

.mobile-links{
  display: none !important;
}
.NavGames{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.NavGamesDrop {
  display: none;
  text-decoration: none;
  position: absolute;
  padding: 20px;
  padding-bottom: 30px;
  background-color: #0077FF;
  border: 4px solid #F6511D;
  border-radius: 0px 50px 50px 50px;
  top: 80px;
  text-align: left;
}
.NavGamesDrop a {
  cursor: pointer;
  padding: 8px 0;
  color: #fff;
  font-weight: 500;
}
.NavGamesDrop a:hover {
  color: #F6511D;
  border-top: 1px solid #FFF;
  border-bottom: 1px solid #FFF;
}
.NavButton {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

}
.NavButton button {
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 25px;
  color: #0077FF;
  font-size: 18px;
  font-weight: 500;
  padding: 10px 30px;
  transition: all .3s ease-in-out;
  margin-right: 15px;
}
.NavButton button:hover {
  color: #F6511D;
  border: 1px solid #F6511D;
  
}



.banner {
  display: flex;
  flex-direction: column;
  height: 700px;
  position: relative;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
}
.bannerContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  align-items: center;
  margin-top: 80px;
  max-width: 1240px;
  margin: 0 auto;
}

.bannerContentLeft {
  text-align: left;
  padding-right: 30px;
  width: calc(100% - 600px);
}
.bannerContentLeft h1{
  margin-bottom: 5px;
}
.bannerContentLeft p {
  font-size: 22px;
  margin: 0 0 20px 0;
}
.bannerContentRight {
  position: relative;
  width: 600px;
  height: 420px;
  flex-shrink: 0;
}
.bannerContentRight img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
  border: 4px #F6511D  solid;
}
.bannerButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.bannerButtons button {
  cursor: pointer;
  background-color: #F6511D;
  border: 1px solid #F6511D;
  border-radius: 25px;
  color: #FFF;
  font-size: 18px;
  font-weight: 500;
  padding: 10px 30px;
  transition: all .3s ease-in-out;
  margin-right: 15px;
}
.bannerButtons button:hover {
  color: #F6511D;
  background-color: #FFF;
}

.CategoryBannerContent{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  align-items: center;
  max-width: 1240px;
  margin: 0 auto;
}

.CategoryBannerContentLeft {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  font-size: 18px;
  padding-right: 30px;
  width: calc(100% - 600px);
  margin-left: 0;
}
.CategoryBannerContentLeft h1 {
  font-size: calc(46px + 2vw);
}
.CategoryBannerContentLeft p {
  margin-bottom: 20px;
}
.CategoryBannerContentRight {
  width: 600px;
  height: 420px;
  flex-shrink: 0;
}
.CategoryBannerContentRight img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
}
.CategoryBannerContentRight iframe{
  width: 100%;
  height: 100%;
  border-radius: 20px;
  border: 0;
}
.CategoryBannerButtons{
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  width: 100%;
}
.CategoryBannerButtons button {
  cursor: pointer;
  background-color: #F6511D;
  border: 1px solid #F6511D;
  border-radius: 25px;
  color: #FFF;
  font-size: 18px;
  font-weight: 500;
  padding: 10px 30px;
  transition: all .3s ease-in-out;
}
.CategoryBannerButtons button:hover {
  color: #F6511D;
  background-color: #FFF;
}

.QuestBannerContent{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  align-items: center;
  max-width: 1240px;
  margin: 0 auto;
}
.QuestBannerContentLeft {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  font-size: 18px;
  padding-right: 30px;
  width: calc(100% - 600px);
  margin-left: 0;
}
.QuestBannerContentLeft h1{
  font-size: calc(32px + 2vw);
}


.QuestBannerContentLeft p {
  font-size: 22px;
  margin-top: 0;
  margin-bottom: 10px;
  max-width: 800px;
}
.QuestBannerContentRight {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 600px;
  height: 420px;
  flex-shrink: 0;
  position: relative;
}
.QuestBannerContentRight img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  object-fit: cover;
}
.QuestBannerContentRight .VideoContainer{
  width: 100%;
  height: 100%;
}
.QuestBannerContentRight iframe{
  width: 100%;
  height: 100%;
  border-radius: 20px;
  border: 0;
  outline: 0;
}
.QuestBannerButtons{
  display: flex;
  flex-direction: row;
  gap: 60px;
  align-items: center;
  width: 100%;
}
.QuestBannerButtons button {
  cursor: pointer;
  background-color: #F6511D;
  border: 1px solid #F6511D;
  border-radius: 25px;
  color: #FFF;
  font-size: 18px;
  font-weight: 500;
  padding: 10px 30px;
  transition: all .3s ease-in-out;
}
.QuestBannerButtons button:hover {
  color: #F6511D;
  background-color: #FFF;
}



.BannerImage {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 420px;
  opacity: 0;
  transition: opacity 2s ease-in-out;
  z-index: 0;
}
.bannerColorBlock{
  position: absolute;
  background-color: #0077FF;
  height: 100%;
  width: 45%;
  top: 0;
  right: 0;
  z-index: -1;
}
.WhatKindOfQuester {
 margin-top: 20px;
}
.WhatKindOfQuester h1 {
  text-align: center;
  margin: 0;
}
.WhatKindOfQuesterSortTabs {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
}
.WhatKindOfQuesterSortTabs button{
  cursor: pointer;
  background-color: #F6511D;
  border: 1px solid #F6511D;
  border-radius: 15px 15px 0 0;
  color: #FFF;
  font-size: 14px;
  font-weight: 500;
  padding: 10px 30px;
  transition: all .3s ease-in-out;
}
/* .WhatKindOfQuesterSortTabsSelected {
  background-color: #FFF;
  color: #000;
} */

.WhatKindOfQuesterSortTabs button:hover {
  color: #FFF;
  border: 1px solid #000;
  background-color: #000;
}
.CategoryCardContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* grid-template-rows: repeat(2, 1fr); */
  gap: 20px;
  max-width: 1400px;
  margin: 20px auto 0;
  padding: 0 16px;
}
.CategoryCard {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  flex: 1 0 30%;
}
.WhatKindOfQuesterContainer {
  background-image: url("../public/assets/QuesterBackground.png");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  /* min-height: 100vh; */
}
.CategoryCardTitleContainer {
  display: flex;
  flex-direction: row;
  justify-content: left;
}
.CategoryCardTitle {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  border: solid 1px #adadad62;
  border-radius: 10px 10px 0 0;
  padding: 6px 10px;
  gap: 6px;
}
.CategoryCardImage {
  width: 100%;
  aspect-ratio: 1.3 / 1;
  object-fit: cover;
}
.CategoryCardContent {
  border: 1px solid #adadad62;
  border-radius: 0 10px 10px 10px;
  height: 100%;
}
.CategoryCardContentDescription {
  padding: 10px;
  font-size: 18px;
  text-align: left;
}
.CategoryCardContentDescription p{
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 0;
}
.CategoryCardContentDescription h2 {
  margin: 0;
  font-size: 18px;
  font-weight: 500;
}
.CategoryCard a{
  height: 100%;
}
.Trending {
 background-image: url("../public/assets/TrendingBackground.png");
 background-repeat: no-repeat;
 background-size: 150%;
 background-position: center;
 max-width: 1400px;
 margin: 60px auto;
 padding: 0 16px;
}
.Trending h1{
  margin: 0 0 20px;
  color: #434343;
}
.Trending h4 {
  margin: 0;
  font-weight: 500;
}
.TrendingCard {
  display: flex;
  flex-direction: column;
  padding: 10px 10px 0;
  border-radius: 18px;
  cursor: pointer;
  height: 100%;
  position: relative;
  /* height: 666px;
  max-height: 50vw; */
}
.TrendingCardImage {
  width: 100%;
  aspect-ratio: 1 / 1.2;
  object-fit: cover;
  border: 2px solid #FFF;
  border-radius: 18px;
}
.Trending .slick-slide>div{
  height: 100%;
  padding: 10px;
}
.Trending .slick-track{
  display: flex !important;
  margin-bottom: 40px;
}
.Trending .slick-slide{
  height: inherit !important;
}

.DiagonalClipA {
  clip-path: polygon(0 0, 100% 0, 100% 58%, 0 78%);
}
.DiagonalClipB {
  clip-path: polygon(0 0, 100% 0, 100% 78%, 0 58%);
}
.TrendingCardLower {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #FFF;
  margin-bottom: 80px;
}
.TrendingCardLower h3 {
  margin: 0;
}
.TrendingCardLower hr {
  color: #FFF;
  width: 75%;
  border: 1px solid #FFF;
  margin-bottom: 0px;
}
.TrendingCardLower p {
  margin: 12px 0 0;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  font-weight: 500;
}
.TrendingCard button {
  display: flex;
  align-items: center;
  position: absolute;
  background-color: #FFF;
  border: 1px solid #FFF;
  border-radius: 30px;
  height: 40px;
  font-size: 16px;
  font-weight: 500;
  padding: 10px 30px;
  transition: all .2s ease-in-out;
  left: 50%;
  transform: translateX(-50%);
  bottom: 20px;
  white-space: nowrap;
}
.TrendingCardLower button:hover {
  color: #F6511D;
  border: 3px solid #fff;
  background-color: #000000;
}

.WhyChooseUs {
  /* max-width: 1920px; */
  background-color:#F6F3F3;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 40px;
  padding: 20px 0 20px 0px;
  margin-bottom: 60px;
}
.WhyChooseUsLeft {
  margin-left:  10%;
}
.WhyChooseUsLeft img{
  width: 80%;
  margin: 20px;
  aspect-ratio: 1 / 1;
  object-fit: cover;
}
.WhyChooseUsRight {
  text-align: left;
  width: 60%;
  display: flex;
  flex-direction: column;
  margin-right: 20%;
}
.WhyChooseUsRight h4 {
  margin: 0;
  font-weight: 500;
}
.WhyChooseUsRight h1 {
  margin: 0;
  color: #434343;
}
.ExpandableTextBox {
  font-size: 14px;
  font-weight: 500;
  transition: all .2s ease-in-out;
  margin: 10px;
  padding: 10px;
  border: 2px solid #ccc;
  border-radius: 10px;
}
.ExpandableTextBox span {
  font-size: 18px;
}

.CheckUsOut {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 60px 60px 80px 60px;
  background-color: #FEEEE8;
  margin-bottom: 60px;
  gap: 40px;
}
.CheckUsOutLeft {
  width: 50%;
}
.CheckUsOutLeftGallery {
 display: grid;
 grid-template-columns: 1fr 1fr 1fr;
 grid-gap: 3px;

 padding: 30px;
}
.CheckUsOutLeftGallery img {
  width: 100%;
  aspect-ratio: 1 / 1;
  object-fit: cover;
}
.CheckUsOutRight {
  width: 50%;
  display: flex;
  flex-direction: column;
  text-align: left;
}
.CheckUsOutRightSocials {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  margin-top: 20px;
}
.CheckUsOutRightSocials img {
  background-color: #FFF;
  border-radius: 50%;
  width: 50px;
  cursor: pointer;

}

.CheckUsOutRight h1{
  margin-bottom: 0px;
}
.CheckUsOutRight p {
  font-size: 18px;
}

.TrustedBy {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 60px;
}
.TrustedBy h3 {
  font-weight: 600;
  margin-bottom: 0;
}
.TrustedBy img {
  width: 70%;
  object-fit: cover;
}

.WhatCustomersSay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #F6F3F3;
  text-align: left;
  padding: 50px 12% 50px 12%;
  margin-bottom: 50px;
}

.WhatCustomersSayUpper p {
  color: #646464;
  margin-bottom: 30px;
}
.WhatCustomersSayUpper h1 {
  margin-bottom: 30px;
}
.WhatCustomersSayLower {
  width: 100%;
  display: flex;
  flex-direction: row;
  /* gap: 4%; */
}
 .WhatCustomersSayLower img {
   width: 30%;
   height: calc(30% / 1);
   border-radius: 8px;
 }
.WhatCustomersSayLowerText{
  margin-left: 4%;
  width: 66%;
  background-color: #FFF;
  padding: 20px;
  border-radius: 5px;
}
.WhatCustomersSayLowerText img {
  width: 40px;
  height: 40px;
  aspect-ratio: 1 / 1;
}
.WhatCustomersSayLowerText p {
  font-size: 12px;
  color: #434343;
}
.WhatCustomersSayLowerText strong {
  font-size: 17px;
  font-weight: 600;
  color: #434343;
}

.ContactUs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #FFF;
  border-radius: 20px;
  text-align: left;
  padding: 30px;
  max-width: 670px;
  margin: 0 auto 60px;
}

.ContactUs h1 {
  margin-bottom: 0;
}
.ContactUs hr {
  width: 140px;
  color: #D9D9D9;
  border: 1px solid #D9D9D9;
}
.ContactUsFormUpper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 20px;
  gap: 20px;
}
.ContactUsFormField {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.ContactUsFormField label {
  font-size: 18px;
  margin-bottom: 6px;
  display: block;
}
.ContactUsFormField input {
  color: #FFF;
  font-size: 14px;
  font-weight: 500;
  padding: 0 10px 0 10px;
  width: 100%;
  height: 40px;
  border-radius: 20px;
  border: 1px solid #D9D9D9;
  margin-bottom: 20px;
  background-color: transparent;
}
.ContactUsFormField input:focus {
  background-color: #ffffff;
  color: #F6511A;
}
.ContactUsFormMessage {
  width: 100%;
}
.ContactUsFormMessage label {
  font-size: 18px;
  margin-bottom: 6px;
  display: block;
}
.ContactUsFormMessage textarea {
  color: #FFF;
  font-size: 14px;
  font-weight: 500;
  padding: 10px;
  width: 100%;
  height: 100px;
  border-radius: 20px;
  border: 1px solid #D9D9D9;
  margin-bottom: 20px;
  background-color: transparent;
}
.ContactUsFormMessage textarea:focus {
  background-color: #ffffff;
  color: #F6511A;
}
.ContactUsButtonContainer{
  width: 100%;
  display: flex;
  justify-content: left;
}
.ContactUsButtonContainer button {
  cursor: pointer;
  border: none;
  width: 140px;
  height: 40px;
  border-radius: 20px;
  background-color: #FFF;
  color: #F6511A;
  font-size: 22px;
  font-weight: 400;
  transition: all .2s ease-in-out;
}
.ContactUsButtonContainer button:hover {
  background-color: #F6511A;
  color: #FFF;
  border: 2px solid #FFF;
}

.Footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 16px 50px 16px;
  /* width: 100%; */
  background-color: #000;
  color: #FFF;
  bottom: 0;
}
.Footer h1 {
  font-weight: 400;
  font-size: 26px;
}
.Footer hr {
  width: 100%;
  color: #D9D9D9;
  border: 1px solid rgb(217 217 217 / 40%);
  margin: 36px 0;
}
.FooterLower {
  text-align: left;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  font-size: 14px;
  max-width: 1400px;
  margin: 10px auto 0;
}
.FooterLower h4{
  margin-bottom: 16px;
}
.FooterLower a {
  text-decoration: none;
  color: #FFF;
  display: block;
  margin-bottom: 10px;
  width: max-content;

}
.ReachUsIconContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  gap : 20px;
}
.FooterNewsletterSignup {
  display: flex;
  flex-direction: column;
  background-color: #131313;
  border-radius: 10px;
  padding: 20px;
  width: 100%;
  max-width: 370px;
}
.FooterNewsletterSignup h4 {
  font-weight: 500;
  font-size: 18px;
  margin-top: 0;
  margin-bottom: 10px;
}
.FooterNewsletterSignup input {
  border: none;
  border-radius: 5px;
  padding: 10px;
  font-size: 12px;
  background-color:#1E1E1E;
  color: #646464;
}
.FooterNewsletterSignup button {
  border: none;
  border-radius: 5px;
  padding: 10px;
  font-size: 12px;
  font-weight: 500;
  background-color: #000;
  color: #FFF;
  cursor: pointer;
  margin-top: 10px;
  margin-left: 5px;
}
.FooterNewsletterSignup button:hover {
  background-color: #F6511A;
  color: #FFF;
}
.FooterNewsletterSignup p {
  color: #646464;
}

.VideoContainer {
  width: 100%;
  height: 100%;
}
.VideoContainer iframe {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.WhatIsThisCategory {
  background-color: #F6F3F3;
  display: flex;
  gap: 50px;
  flex-direction: row;
  align-items: center;
  text-align: center;
  margin: 50px auto 50px;
  padding: 40px 16px;
}
.WhatIsThisCategoryLeft {
  position: relative;
  width: 40%;
  aspect-ratio: 1.5 / 1;
  margin-left: 20px;
}
.WhatIsThisCategoryLeftColorBlock {
  position: absolute;
  width: 81.6%;
  height: 160px;
  transform: translate(-4%, -15%);
  z-index: 0;
  border-radius: 10px;
}
.WhatIsThisCategoryLeft img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}
.WhatIsThisCategoryLeftImage{
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 2s ease-in-out;
  z-index: 0;
  border-radius: 10px;
}
.WhatIsThisCategoryRight {
  width: 50%;
  display: flex;
  flex-direction: column;
  text-align: left;
}
.WhatIsThisCategoryRight h1 {
  margin: 0;
  padding: 0;
}
.WhatIsThisCategoryRight hr{
  transform: translate(-72%, 0);
  width: 40%;
  color: #F6511A;
  border: 2px solid #F6511A;
}
.WhatIsThisCategoryRight button {
  width: 140px;
  border: none;
  border-radius: 20px;
  padding: 10px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: .2s ease-in-out;
}

.QuestCardDisplay {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  padding: 0 16px;
  max-width: 1200px;
  margin: 0 auto 50px;
}
.QuestCard {
  background-color: #F6F3F3;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(33.33% - 14px);
  position: relative;
  cursor: pointer;
}
.QuestCard > a {
  width: 100%;
  display: block;
}
.QuestCardLocationContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
  width: 100%;
  position: relative;
}
.QuestCardLocationContainer img {
  width: 60px;
  height: 60px;
  position: absolute;
  top: 0;
  right: 20px;
  fill: #FFF;
}
.QuestCardLocation {
  font-size: 16px;
  font-weight: 500;
  color: #F6511A;
  padding: 4px 16px 4px 16px;
  border-radius: 0px 10px 10px 0px;
  background-color: #fff;
}
.QuestCardBody {
  padding: 20px 20px 0;
  margin-bottom: 80px;
}
.QuestCardImage{
  width: 100%;
  height: 386px;
  border-radius: 23px 23px 250px 250px;
  background-position: center;
  background-size: cover;
  transition: all 0.1s ease;
}
.QuestCardImage:hover {
  border: 7px solid #F6511A !important;
  cursor: pointer;
}
.QuestCardBackground{
  width: 100%;
  height: 386px;
  border-radius: 23px 23px 250px 250px;
  position: absolute;
  z-index: 0;
  left: 0;
  top: 0;
  object-fit: cover;
}
.QuestCardBackground:hover {
  border: 7px solid #F6511A !important;
  cursor: pointer;
}
.QuestCardTitle {
  position: relative;
  font-size: 28px;
  font-weight: bold;
  margin-top: 160px;
  color: #FFF;
  text-shadow:
  -2px -2px 0 #000,  
  2px -2px 0 #000,
  -2px 2px 0 #000,
  2px 2px 0 #000;
  /* z-index: 9999; */
}
.QuestCardSubtitle {
  position: relative;
  font-size: 18px;
  font-weight: 500;
  color: #FFF;
  text-shadow:
  -1px -1px 0 #000,  
  1px -1px 0 #000,
  -1px 1px 0 #000,
  1px 1px 0 #000; 
}
.QuestCardDescription {
  font-size: 16px;
  font-weight: 500;
  color: #555;
  margin-bottom: 20px;
}
.QuestCardAvailability {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 600;
  color: #F6511A;
}
.QuestCardbtns{
  display: flex;
  justify-content: center;
  gap: 16px;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  width: 100%;
}
.QuestCardFlag {
  display: flex;
  margin: 0 0 0 5px;
  padding: 0;
  width: 60px;

  border-radius: 5px;
}

.QuestCardPrice {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 20px;
}
.QuestCardButton {
  background-color: #FFB503;
  color: #fff;
  border: none;
  padding: 6px 24px;
  height: 40px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 26px;
  transition: all .1s ease-in-out;
}
.QuestCardButton:hover {
  background-color: #F6511A !important;
}


.News {
display: flex;
flex-direction: column;
}
.NewsSpacer {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 80px;
}
.NewsSpacerLeft {
  width: 55%;
}
.NewsSpacerRight {
  width: 45%;
  background-color: #0077FF;
}
.NewsBanner {
  background-image: url("https://i.ibb.co/Jv82DWw/News-Banner-Background.png");
  width: 100%;
  height: 600px;
  background-position: center;
  background-size: 100% 101%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
.NewsBannerLeft {
  width: 40%;
}
.LatestPostsContainer {
  margin: 0 0 0 60px;
  max-width: 500px;
  padding: 20px 40px 20px 40px;
  background-color: #0077FF;
  border-radius: 50px;
  border-right: 16px solid #F6511A;
  text-align: left;
  border-bottom: 16px solid #F6511A;
}
.LatestPostsContainer h4{
  margin-bottom: 10px;
}
.LatestPosts {
  color: #FFF;
  width: 100%;
  height: 100%;
  text-align: left;
}
.LatestPostsItem {
  color: #FFF;
  text-decoration: none;
}
.LatestPostsItem  p{
  margin: 0 0 0 0;
}
.LatestPostsItem:hover {
  color: #F6511D;
}


.NewsBannerRight {
  width: 60%;
  display: flex;
  flex-direction: column-reverse;
}

.NewsFilterButtons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 60px;
  margin: 20px;
}
.NewsFilterButtons button {
  background-color: #0077FF;
  color: #fff;
  border: none;
  padding: 12px 30px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 26px;
  transition: all .2s ease-in-out;
}
.NewsFilterButtons button:hover {
  background-color: #F6511A;
  color: #fff;
}

.NewsCardContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 180px;
  gap: 40px;
}
.NewsCard {
  display: flex;
  width: 100%;
  cursor: pointer;
  transition: all .2s ease-in-out;
}
.NewsCard:hover .NewsCardLeft{
  border-top: 20px solid #F6511A;
  border-bottom: 20px solid #F6511A;
  transition: all .2s ease-in-out;
}
.NewsCard:hover h1 {
  color: #F6511A;
  text-shadow:
  -2px -2px 0 #FFF,  
  2px -2px 0 #FFF,
  -2px 2px 0 #FFF,
  2px 2px 0 #FFF;
  transition: all .2s ease-in-out;
}

.NewsCardLeft {
  background-color: #0077FF;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-left: 20px solid #F6511A;
  text-align: left;
  width: 40%;
  padding: 40px;
  margin-left: 50px;
  transition: all .2s ease-in-out;
}
.NewsCardLeft h1 {
  font-size: 26px;
  margin-bottom: 0px;
}
.NewsCardLeft h4 {
  font-size: 18px;
  margin-bottom: 0px;
  margin-top: 0;
  font-weight: 500;
}
.NewsCardLeft img {
  width: 85%;
  height: 85%;
  transform: translate(20%, 20%);
  object-fit: cover;
  border-radius: 10px;
  aspect-ratio: 1/1;
}
.NewsCardRight {
  background-image: url("https://i.ibb.co/Wt6mdxZ/pattern.png");
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  width: 50%;
  padding: 40px;
}
.NewsCardRight p {
  background-color: #258bfff1;
  padding: 30px;
  border-radius: 10px;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
}

.NewsItem{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-top: 80px;
  padding-top: 60px;
  padding: 60px 16px 16px;
  color: #fff;
  background-color: #0077FF;
}
.NewsItemBackButton{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-weight: 500;
  height: 40px;
  cursor: pointer;
  background-color: transparent;
  color: #fff;
  font-size: 16px;
  border: 2px solid #FFF;
  border-radius: 28px;
  transition: all .1s ease-in-out;
  padding: 6px 24px;
}
.NewsItemBackButton:hover{
  background-color: #FFF;
  color: #F6511A;
  border: 2px solid #FFF;
}
.NewsItemContent{
  width: 100%;
  height: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.NewsItemContent h1 {
  margin: 50px 0 0px 0;
}
.NewsItemContent h4 {
  font-size: 24px;
  margin: 0px 0 30px 0;
  font-weight: 500;
}
.NewsItemContent img {
  border: 4px solid #FFF;
  border-radius: 10px;
  max-width: 600px;
  width: 100%;
  height: auto;
}
.NewsItemContent p {
  font-size: 18px;
  font-weight: 500;
  text-align: left;
}

.QuestInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 60px;
}
.QuestInfoSeeAll {
  position: absolute;
  bottom: 0;
  right: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
  width: 100%;
  font-weight: 600;
}
.QuestInfoSeeAllText {
  color: #FFF;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: right;
  cursor: pointer;
  border-bottom: 3px solid #FFF;
  /* text-shadow:
  -1px -1px 0 #000,  
  1px -1px 0 #000,
  -1px 1px 0 #000,
  1px 1px 0 #000; */
}
.QuestInfoSeeAllContent a {
  text-decoration: none;
}
.QuestInfoSeeAllText:hover {
  color: #F6511D;
  border-bottom: 3px solid #F6511D;
}

.QuestInfoImageDiv {
  position: relative;
  width: 100%;
  overflow: hidden;
  background-color: #000;
  padding: 16px;
  /* margin: 50px 0; */
  height: 66vh;
}
.BackgroundImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  opacity: 0;
  transition: opacity 2s ease-in-out;
  z-index: 0;
}
.Show {
  opacity: 1;
  z-index: 3;
}
.QuestInfoImageDivContentOuter {
  position: relative;
  width: 40%;
  height: 100%;
  margin-left: 55%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  z-index: 4;
}
.QuestInfoImageDivContentInner{
 text-align: left;
 padding: 10px;
 border-radius: 12px;
 padding: 20px;
 color: #FFF;
}

.QuestInfoImageDivContentOuter button {
  color: #FFF;
  border: none;
  padding: 12px 30px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 26px;
  margin-top: 10px;
  transition: all .2s ease-in-out;
}
.QuestInfoImageDivContentOuter button:hover {
  font-weight: 700;
  
}
.QuestInfoSteps {
  color: #FFF;
  width: 100%;
  padding: 50px 16px;
}
.QuestInfoStepsUpper hr {
  width: 10%;
  height: 2px;
  background-color: #fff;
  border: none;
  margin-bottom: 30px;
}
.QuestInfoStepsLower {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 20px;
}
.QuestInfoStepsLowerItem {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.QuestInfoStepsLowerItem img{
  max-width: 106px;
}
.QuestInfoStepsLowerItem h3 {
  margin: 20px 0 6px 0;
  font-size: 20px;
  font-weight: 600;
}
.QuestInfoStepsLowerItem h4{
  font-size: 20px;
  font-weight: 500;
}
.QuestInfoOverview {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1200px;
  margin: 60px auto 0;
  padding: 0 16px;
}
.QuestInfoOverviewItem {
  display: flex;
  width: 100%;
}
.QuestInfoOverviewItemIconContainer{
  border-radius: 50%;
  width: 106px;
  aspect-ratio: 1/1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.QuestInfoOverviewItemIconContainer img {
  fill: #FFF;
  color: #FFF;
  max-width: 45px;
}

.QuestInfoOverviewItemText {
  background-color: #FEEEE8;
  padding:20px 30px;
  border-radius: 0px 50px 50px 50px;
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  text-align: left;
  margin-bottom: 20px;
  width: 100%;
  height: 100%;
}
.QuestInfoOverviewItemText h2{
  margin-bottom: 16px;
}
.QuestInfoOverviewItemText p{
  font-size: 24px;
  margin: 0 0 16px 0;
}
.QuestInfoButton{
  color: #FFF;
  border: none;
  padding: 12px 30px;
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
  border-radius: 26px;
  margin-top: 40px;
  transition: all .2s ease-in-out;
  width: 40%;
  height: 60px;
}
.QuestInfoButton:hover {
  font-weight: 700;
  color: #F6511D;
  background-color: #FFF !important;
  border: 3px solid #F6511D;
  width: 50%;
}

.PrivacyPolicy{
  color: #FFF;
  font-weight: 600;
  text-align: left;
  margin-top: 80px;
  line-height: 18px;
  opacity: 1;
}
.PrivacyPolicyContent{
  padding: 40px;  
  background-color: #0077FF;
  height: 100%;
  width: 100%;
}
.PrivacyPolicy div li {
  list-style: none;
}
.PrivacyPolicy div h3 {
  margin-top: 60px;
}
.PrivacyPolicyContent a {
  color: #f6d51a;
}

.Terms {
  color: #FFF;
  font-weight: 600;
  text-align: left;
  margin-top: 80px;
  line-height: 18px;
  opacity: 1;
}
.TermsContent{
  padding: 40px;  
  background-color: #0077FF;
  height: 100%;
  width: 100%;
}
.TermsContent a {
  color: #f6d51a;
}
.TermsContent h2 {
  margin-top: 40px;
}
.TermsContent h3 {
  margin-top: 40px;
}
.Terms div li {
  list-style: none;
}
.Terms div h3 {
  margin-top: 60px;
}

.Creators{
  margin-top: 80px;
}
.Creators img {
  width: 100%;
  max-width: 1400px;
}
.CreatorsContent{
  padding: 40px;
  text-align: left;
}

.FAQ {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 60px;
  color: #FFF;
}
.FAQ hr{
  width: 100%;
  margin: 0;
  outline: 0;
  border: 0;
  border-bottom: 1px solid #ffffff;
  padding: 6px;
}
.FAQContainer{
  width: 100%;
  max-width: 1200px;
  padding: 60px 16px;
}

.FAQItemDisplay{
  margin: 60px 0 0 0;
  margin-bottom: 0;
}
.FAQItem {
  margin-bottom: 25px;
  width: 100%;
  cursor: pointer;
  text-align: left;
}
.FAQQuestion {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
  font-size: x-large;
  font-weight: 800;
  width: 100%;
}
.FAQQuestion p {
  font-size: 16px;
  font-weight: 600;
}

.FAQAnswer{
  margin-bottom: 0;
  font-weight: 600;
  width: 100%;
}
.FAQAnswer a {
  text-decoration: none;
  color: #ff0080;
}
.FAQAnswer a:hover {
  text-decoration: underline;
}

.FAQItem p {
  font-size: 16px;
  font-weight: 400;
}
.FAQAnswerHide {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.FAQAnswerShow {
  max-height: 200px;
}

.Booking {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 60px;
  background-color: #0077FF;
  color: #FFF;
  min-height: 60vh;
  padding: 60px 16px;
}
.booking-container{
  max-width: 660px;
}
.Booking h1{
  margin-bottom: 30px;
}
 
.Booking hr{
  width: 100%;
  height: 12px;
  background-color: #F6511A;
  border: none;
  top: 0;
  margin: 0;
}
.BookingStep{
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
}
.BookingStepColumn {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 16px;
}
.BookingStepRow {
  width: 100%;
  display: flex;
  gap: 16px;
}
.BookingStepRow.promocode{
  align-items: flex-end;
  margin-bottom: 20px;
}
.promocode-response{
  margin-top: -16px;
  margin-bottom: 16px;
  text-align: left;
  color: #F6511A;
  font-size: 14px;
  font-weight: 500;
}
.BookingStepRow.promocode .BookingStepColumn{
  margin: 0;
}
.BookingStepRow label {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 4px;
  display: block;
  text-align: left;
  width: 100%;
}
.BookingStepRow button {
  font-size: 16px;
  font-weight: 500;
  height: 40px;
  border: none;
  border-radius: 20px;
  background-color: #FFF;
  color: #0077FF;
  cursor: pointer;
  padding: 6px 30px;
}
.BookingStepRow button:hover {
  color: #F6511A;
}
.BookingStepSelect {
  font-size: 16px;
  font-weight: 500;
  height: 40px;
  padding: 6px 16px;
  border: none;
  border-radius: 20px;
  background-color: #FFF;
  color: #FF8800;
  width: 100%;
  outline: 0 !important;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAUCAMAAACtdX32AAAAdVBMVEUAAAD///8AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAhMdQaAAAAJ3RSTlMAAAECAwQGBwsOFBwkJTg5RUZ4eYCHkJefpaytrsXGy8zW3+Do8vNn0bsyAAAAYElEQVR42tXROwJDQAAA0Ymw1p9kiT+L5P5HVEi3qJn2lcPjtIuzUIJ/rhIGy762N3XaThqMN1ZPALsZPEzG1x8LrFL77DHBnEMxBewz0fJ6LyFHTPL7xhwzWYrJ9z22AqmQBV757MHfAAAAAElFTkSuQmCC);
  background-position: 100%;
  background-repeat: no-repeat;
}

.BookingStepRow p {
  font-size: 12px;
  font-weight: 500;
  text-align: left;
  width: 100%;
  margin: 5px 0 0;
}
.BookingStepInput {
  height: 40px;
  font-size: 16px;
  font-weight: 500;
  padding: 6px 16px;
  border: none;
  border-radius: 20px;
  background-color: #FFF;
  color: #FF8800;
  width: 100%;
  outline: 0 !important;
}
.BookingDatePickerContainer{
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-between;
width: 100%;
border: none !important;
}
.BookingDatePickerContainer div{
  font-family: poppins, sans-serif;
  font-weight: 500;
}
.BookingDatePickerContainer button{
  border-radius: 10px;
  padding: 0;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.react-datepicker__navigation{
  top: 5px;
}
.react-datepicker__navigation-icon--previous::before{
  right: -4px;
}
.react-datepicker__navigation-icon--next::before{
  left: -4px;
}
.BookingDatePickerContainer input{
  color: #FF8800;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  height: 40px;
  padding: 6px 16px;
  outline: 0 !important;
}
.BookingDatePicker {
  font-size: 16px;
  border: 1px solid #0077FF;
  border-radius: 20px;
  width: 100%;
}
.react-datepicker__day--today{
  background-color: #0077FF !important;
  border-radius: 50% !important;
  color: #FFF !important;
}
.react-datepicker__day--selected{
  border: 2px solid #F6511A !important;
  border-radius: 50% !important;
  background-color: #FFF !important;
  color: #000 !important;
}
.react-datepicker__time-list-item--selected{
  border: 2px solid #F6511A !important;
  background-color: #FFF !important;
  border-radius: 25% !important;
  color: #000 !important;
}

@keyframes shake {
  0%, 100% {
    transform: translateX(0);
  }
  20%, 60% {
    transform: translateX(-10px);
  }
  40%, 80% {
    transform: translateX(10px);
  }
}

.BookingStepMissingField {
  animation: shake 0.5s cubic-bezier(.36,.07,.19,.97) both;
  border: 2px solid #ff3030;
}
.BookingStepMissingField input {
  border: 2px solid #ff3030;
}

.BookingStepPromoButton{
  font-size: 16px;
  font-weight: 500;
  border: none;
  border-radius: 20px;
  background-color: #FFF;
  color: #FF8800 !important;
  cursor: pointer;
  height: 40px;
  padding: 6px 24px;
}
.BookingStepPromoButton:hover {
  color: #FFF !important;
  background-color: #FF8800;
}
.BookingStepMailingListInputContainer{
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  width: 100%;
}
.BookingStepMailingListInputContainer button{
  border-radius: 50%;
  width: 28px;
  height: 28px;
  flex-shrink: 0;
  cursor: pointer;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  border: 2px solid #FFF;
  color: #FFF;
  background-color: #0077FF;
}
.BookingStepMailingListInputContainer label{
  font-size: 14px;
  font-weight: 400;
  text-align: left;
}
.BookingStepTOSInputContainer{
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  width: 100%;
  margin-bottom: 8px;
}
.BookingStepTOSInputContainer button{
  border-radius: 50%;
  width: 28px;
  height: 28px;
  flex-shrink: 0;
  cursor: pointer;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  border: 2px solid #FFF;
  color: #FFF;
  background-color: #0077FF;
}
.BookingStepTOSInputContainer label{
  font-size: 14px;
  font-weight: 400;
  text-align: left;
}
.BookingStepTOSInputContainer label a{
  color: #FF8800;
}
#payment-form {
  margin-top: 0;
  padding: 0;
  width: 100%;
}
#submit{
  font-size: 16px;
  font-weight: 600;
  border: none;
  border-radius: 20px;
  background-color: #FFF;
  color: #0077FF;
  cursor: pointer;
  height: 40px;
  padding: 6px 30px;
  margin-top: 60px;
}
#submit:hover {
  color: #F6511A;
}
#BookingStepBackButton{
  font-size: 16px;
  font-weight: 500;
  height: 40px;
  border: none;
  border-radius: 20px;
  background-color: #FFF;
  color: #0077FF;
  cursor: pointer;
  padding: 6px 30px;
  margin-bottom: 10px;
}
#BookingStepBackButton:hover {
  color: #F6511A;
}
.BookingStepError {
  margin-bottom: 16px;
  text-align: left;
  color: #F6511A;
  font-size: 14px;
  font-weight: 500;
}
.BookingStepTwoInfoRow{
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  flex-direction: row;
  width: 100%;
  border: 3px solid #FF8800;
  border-radius: 8px;
  padding: 10px 14px;
  margin-top: 10px;
  text-align: left;
}
.BookingStepTwoInfoColumn{
  flex-basis: calc(50% - 5px);
}
.BookingStepTwoInfoColumn span{
  font-size: 14px;
  font-weight: 600;
}
.BookingStepTwoInfoColumn p{
  margin: 0;
}

.BookingConfirmation{
  margin: 60px;
  font-weight: 600;
  font-size: larger;
  color: #FFF;
}

.NotFound{
  margin-top: 120px;
}

.QuestClient{
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #0077FF;
  color: #FFF;
  padding-bottom: 20px;
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
  overflow: hidden;
  padding: 16px;
}
.QuestClientCenterContent{
  justify-content: center;
}
.QuestClient button{
  font-size: 16px;
  font-weight: 500;
  border: none;
  border-radius: 50px;
  background-color: #FFF;
  color: #0077FF;
  cursor: pointer;
  height: 40px;
  padding: 6px 16px;
  margin-top: 60px;
}
.QuestClient label{
  font-size: x-large;
  font-weight: 600;
  margin-bottom: 10px;
  margin-top: 10px;
  width: 100%;
}
.QuestClient input{
  font-size: 16px;
  font-weight: 500;
  height: 40px;
  margin-bottom: 10px;
  margin-top: 10px;
  border-radius: 50px;
  border: none;
  padding: 6px 16px;
  width: 100%;
  outline: 0 !important;
  max-width: 400px;
}
.QuestClient button:hover {
  color: #F6511A;
}
.CountDown{
  background-color: #000000e1;
  padding: 40px;
  border-radius: 20px;
  width: 100%;
  max-width: 760px;
  height: calc(100vh - 20px);
  overflow: auto;
}
.CountDown > svg{
  max-width: 64px;
  height: auto;
  margin-bottom: 10px;
}
.CountDownText{
  margin: 0 0 12px 0;
}

.QRCode{
  border: 4px solid #FFF;
  border-radius: 12px;
}
.LaunchQuestButton{
  background-color: #000000 !important;
  height: 46px !important;
  width: 260px !important;
  margin-top: 0 !important;
  transition: all .2s ease-in-out;
}
.LaunchQuestButton:hover {
  color: #F6511A !important;
  border: 3px solid #F6511A !important;
  background-color: transparent !important;
}

.QuestRoom{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #0077FF;
  color: #000000;
  height: 100vh;
  width: 100vw;
  margin: 0;
  overflow: hidden;
}
@media (min-width: 768px) and (min-height: 600px){
  .QuestRoom {
    padding: 16px;
  }
}

.QuestRoomContent{
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 80vw;
  overflow: auto;
  background-color: #00000065;
  color: #fff;
  border-radius: 20px;
  border: 3px solid rgb(255 255 255 / 80%);
  margin: 0;
  transition: all .2s ease-in-out;
}
.QuestRoomContent p {
  font-size: 16px;
  font-weight: 400;
  margin: 0 0 5px 0;
}
.Centered{
  justify-content: center;
}
.JoinButtons{
  font-size: 16px;
  font-weight: 600;
  border-radius: 20px;
  background-color: #FFF;
  color: #f6511a;
  cursor: pointer;
  padding: 6px 24px;
  height: 40px;
  transition: all .2s ease-in-out;
  border: 3px solid #fff;
  margin: 10px;
  min-width: 120px;
}
.JoinButtons:hover {
  color: #F6511A;
  background-color: transparent;
  border: 3px solid #F6511A;
}

.FullTeamOrRejoin{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #00000065;
  color: #f3f3f3;
  margin: 0;
  padding: 30px;
  border-radius: 20px;
  overflow: hidden;
}
.FullTeamOrRejoin input{
  font-size: 16px;
  font-weight: 500;
  height: 40px;
  margin-bottom: 10px;
  margin-top: 10px;
  border-radius: 50px;
  border: none;
  padding: 6px 16px;
  width: 100%;
  max-width: 260px;
  outline: 0 !important;
}
.FullTeamOrRejoin button{
  font-size: 16px;
  font-weight: 600;
  border: none;
  border-radius: 50px;
  background-color: #FFF;
  color: #0077FF;
  cursor: pointer;
  height: 40px;
  padding: 6px 30px;
}
.FullTeamOrRejoin button:hover {
  color: #F6511A;
}
.FullTeamOrRejoin h2{
  margin-bottom: 20px;
}

.JoinQuest{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #00000065;
  color: #f3f3f3;
  margin: 0;
  padding: 30px;
  border-radius: 20px;
  overflow: hidden;
  border: 3px solid rgb(255 255 255 / 80%);
}
.JoinQuest h2{
  margin-bottom: 30px;
}
.JoinQuest .question-text{
  margin-bottom: 10px;
}
.JoinQuest .input-question{
  margin: 30px 0 10px 0;
}
.JoinQuest input{
  font-size: 16px;
  height: 40px;
  font-weight: 500;
  margin-bottom: 24px;
  border-radius: 50px;
  border: none;
  padding: 6px 16px;
  width: 100%;
  max-width: 260px;
  outline: 0 !important;
}
.JoinQuest button{
  font-size: 16px;
  font-weight: 600;
  border: none;
  border-radius: 20px;
  background-color: #FFF;
  color: #0077FF;
  cursor: pointer;
  height: 40px;
  padding: 6px 30px;
  transition: all .2s ease-in-out;
}
.JoinQuest button:hover {
  color: #F6511A;
}

.WaitForCaptain p{
  margin-left: 8rem;
  margin-right: 8rem;
  text-align: left;
}
.RulesAndBriefing{
  overflow: auto;
 
  padding: 20px;
}
.RulesAndBriefing a{
  color: #F6511A;
}
.RulesAndBriefing p{
  text-align: left;
  margin: 5px 0 5px 0;
}
.QuestTimerContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: start;
}
.QuestTimerContainer h3 {
  margin: 0;
  padding: 0;
  /* font-size: 2rem; */
  font-weight: 700;
  color: #fff;
}
.QuestTimer{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #fff;
  margin: 0;
  overflow: hidden;
}
.QuestTimer p{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
  margin: 10px;
  border-radius: 4px;
  border: 2px solid #fff;
  padding: 7px 10px;
}

.QuestClientHeader{
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 60px;
}
.QuestClientHeaderLeft{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 15%;
  margin-right: 2.5%;
}
.QuestClientHeaderRight{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 80%;
  margin-right: 2.5%;
}
#ProgressBarContainer {
  width: 100%;
  background-color: #f0f0f0;
  height: 16px;
  border-radius: 5px;
  overflow: hidden;
}
#ProgressBar {
  height: 100%;
  background-color: #F6511A;
  width: 0%;
  transition: width 0.5s ease;
}
#TimerBarContainer {
  width: 100%;
  background-color: #f0f0f0;
  height: 16px;
  border-radius: 5px;
  overflow: hidden;
}
#TimerBar {
  height: 100%;
  background-color: #F6511A;
  width: 0%;
  transition: all 0.5s ease;
}


.FooterNav{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 80px;
  background-color: #1b1b1b;
  color: #BDBDBD;
}
.FooterNavItem{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all .2s ease-in-out;
  font-size: large;
  font-weight: 500;
  border: 1px solid #BDBDBD;
  width: 100%;
  height: 80px;
  background-color: #1b1b1b;
}
.FooterNavItem:hover {
  color: #FFF;
  border-bottom: 20px solid #F6511A;

}
@keyframes pulseOrange {
  0% {
      color: #ffffff;
      border-bottom: 0px;
  }
  3% {
      color: #F6511A;
      border-bottom: 20px solid #F6511A;
  }
  6% {
      color: #ffffff;
      border-bottom: 0px;
  }
  9% {
      color: #F6511A;
      border-bottom: 20px solid #F6511A;
  }
  12% {
      color: #ffffff;
      border-bottom: 0px;
  }
  100% {
      color: #ffffff;
      border-bottom: 0px;
  }
}
.FooterNavItemNew {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  font-size: large;
  font-weight: 500;
  border: 1px solid #BDBDBD;
  width: 100%;
  height: 80px;
  background-color: #1b1b1b;
  animation: pulseOrange 10s infinite;
}
.FooterNavItem:hover {
  color: #FFF;
  border-bottom: 20px solid #F6511A;

}
.QuestTab{
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 18px;
  width: 100%;
  color: #fff;
}
.QuestTabText{
  text-align: left;
  width: 100%;
  max-width: 700px;
  height: calc(100vh - 400px);
  overflow-y: scroll;
  padding: 20px;
  color: #353535;
  scrollbar-width: thick;
  scrollbar-color: #0077FF #000000;
  border-radius: 20px;
  background-color: #fffaf4;
  min-height: 250px;
}
.QuestTabImage{
  width: 100%;
  max-width: 700px;
  max-height: 800px;
  padding-bottom: 160px;
  object-fit: contain;
}
.QuestTabAnswer{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: #353535;
  margin: 0 0 5px;
}
.QuestTabAnswer input{
  font-size: 16px;
  font-weight: 500;
  padding: 6px 16px;
  width: 100%;
  height: 40px;
  border-radius: 20px;
  outline: 0 !important;
  border: none;
  color: #353535;
  background-color: #f1f1f1;
  transition: all .2s ease-in-out;
}
.QuestTabInputWrongAnswer{
  animation: flash-red 1s ease;
}
@keyframes flash-red {
  0% {
    background-color: red;
  }
  100% {
    background-color: #f1f1f1; /* or original color */
  }
}

.QuestTabAnswer button{
  font-size: 16px;
  font-weight: 600;
  padding: 6px 20px;
  border-radius: 20px;
  border: 2px solid #f1f1f1;
  color: #0077ff;
  background-color: #f1f1f1;
  height: 40px;
  margin-left: 10px;
  cursor: pointer;
}
.QuestTabAnswer button:hover {
  color: #F6511A;
  border: 2px solid #F6511A;
}
.QuestTabAudioPlayer{
  font-weight: 800;
  border-radius: 20px;
  width: 60%;
  position: absolute;
  bottom: 84px;
}
.rhap_container button,
.rhap_progress-indicator {
    color: #f1f1f1 !important;
}
.rhap_container {
    background-color: #353535 !important;
}
.rhap_current-time,
.rhap_total-time {
    color: #f1f1f1 !important;
}
.rhap_container {
    font-family: Arial, sans-serif !important;
    max-width: 540px;
}
.QuestTabEndPopupButton{
  font-size: 16px;
  font-weight: 500;
  padding: 6px 24px;
  height: 40px;
  background: #FFF;
  color: #0077FF;
  border: 0;
  border-radius: 50px;
  margin-bottom: 10px;
  cursor: pointer;
  margin-top: 20px;
  width: 200px;
  transition: all .2s ease-in-out;

}
.QuestTabEndPopupButton:hover {
  color: #F6511A;
  border: 2px solid #F6511A;
  background-color: transparent;
  width: 300px;
}

.EndStatePopup{
 width: 100%;
 height: 100%;
 position: absolute;
 top: 0;
 left: 0;
 z-index: 9999;
 background-color: rgba(0, 0, 0, 0.6);
 display: flex;
 justify-content: center;
 align-items: center;
 color: #fff;
}
.EndStatePopupContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 18px;
  width: 100%;
  max-width: 700px;
  /* height: calc(100vh - 400px); */
  padding: 20px;
  color: #353535;
  scrollbar-width: thick;
  scrollbar-color: #353535 #000000;
  border-radius: 20px;
  background-color: #fffaf4;
}
.EndStatePopupContainer button{
  font-size: 16px;
  font-weight: 500;
  padding: 6px 24px;
  height: 40px;
  background: #0077FF;
  color: #FFF;
  border: 0;
  border-radius: 50px;
  margin-bottom: 10px;
  cursor: pointer;
  width: 200px;
  transition: all .2s ease-in-out;
}
.EndStatePopupContainer button:hover {
  color: #F6511A;
  border: 2px solid #F6511A;
  background-color: transparent;
  width: 300px;
}
/* .EndStatePopupContent{

} */


.HintsTab{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  font-size: 18px;
  width: 100%;
  height: calc(100% - 153px);
}
.HintsTab button{
  font-size: 16px;
  font-weight: 500;
  padding: 6px 24px;
  height: 40px;
  background: #FFF;
  color: #0077FF;
  border: 0;
  border-radius: 50px;
  margin-bottom: 10px;
  cursor: pointer;
}
.HintsTabHintsContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 700px;
  height: calc(100vh - 400px);
  padding: 20px;
  color: #353535;
  scrollbar-width: thick;
  scrollbar-color: #353535 #000000;
  border-radius: 20px 0px 20px 20px;
  background-color: #fff;
  overflow-y: scroll;
  min-height: 250px;
}
.HintsTabHintsContainerHint{
  color: #353535;
  padding: 20px;
  margin-top: 10px;
  border-top: 3px solid #BDBDBD;
  border-bottom: 3px solid #BDBDBD;
}
.HintsTabHintsContainerHint img{
  width: 100%;
  max-width: 500px;
}

.HistoryTab{
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 18px;
  width: 100%;
  height: calc(100% - 153px);
  padding: 20px 0;
  color: #fff;
}

.HistoryTabEventContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  font-size: 18px;
  width: 100%;
  max-width: 700px;
  height: 100%;
  /* max-height: 420px; */
  padding: 20px;
  color: #353535;
  scrollbar-width: thick;
  scrollbar-color: #353535 #000000;
  border-radius: 20px ;
  background-color: #fffaf4;
  overflow-y: scroll;
}
.HistoryTabEvent{
  text-align: left;
  display: flex;
  flex-direction: column;
  font-size: 18px;
  width: 100%;
  color: #353535;
  border-top: 2px solid #BDBDBD;
  padding: 10px;
}
.HistoryTabEvent p{
  font-size: 16px;
  margin: 0;
}

.MapTab{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.MapContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin-bottom: 80px;
}

.EvidenceTab{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding:  20px 0;
}
.EvidenceTabContent{
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  width: 100%;
  max-width: 700px;
  overflow-y: scroll;
  border-radius: 20px;
  background-color: #fffaf4;
  height: calc(100vh - 350px);
  color: #353535;
  padding: 16px;
}
.EvidenceTabContent, .EvidenceTabItem {
  box-sizing: border-box;
}
.EvidenceTabItem{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  width: 100%;
  color: #353535;
  padding: 0 20px 0 20px;
}
.EvidenceTabItem img{
  max-height: 310px;
  object-fit: contain;
  border-radius: 20px;
}
.EvidenceTabAudioPlayer{
  font-weight: 800;
  border-radius: 20px;
  width: 60%;
}

.QuestionTab{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: calc(100% - 153px);
  padding: 20px 0;
}
.QuestionTabContent{
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  width: 100%;
  max-width: 700px;
  height: 100%;
  overflow-y: scroll;
  border-radius: 20px;
  background-color: #fffaf4;
  color: #353535;
  padding: 16px;
}
.QuestionTabItem{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  width: 100%;
  border-top: 3px solid #BDBDBD;
  border-bottom: 3px solid #BDBDBD;
  margin-top: 20px;
}
.QuestionTabItem h4 {
 margin-bottom: 20px;
}
.QuestionTabItemOptions {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 40px;
    margin-bottom: 20px;
}
.QuestionTabItemOptions p{
  font-size: 16px;
  margin: 0;
  border: 2px solid #BDBDBD;
  border-radius: 20px;
  padding: 15px;
  cursor: pointer;
  transition: all .2s ease-in-out;
}
.QuestionTabItemOptions p:hover{
  color: #BDBDBD;
  border: 2px solid #F6511A;
  background-color: #353535;
}
.QuestionTabItemOptionSelected{
  color: #ffffff;
  border: 2px solid #F6511A;
  background-color: #353535;
}
.QuestionTabSubmitButton{
  background-color: #0077FF;
  color: #fff;
  border: none;
  padding: 12px 30px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 26px;
  transition: all .2s ease-in-out;
  margin-top: 20px;
  width: 200px;
}
.QuestionTabSubmitButton:hover {
  background-color: #F6511A;
  color: #fff;
  width: 300px;
}
.QuestionTabConfirmPopup{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
}
.QuestionTabConfirmPopupContent{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fffaf4;
  color: #353535;
  border-radius: 20px;
  width: 100%;
  max-width: 400px;
  padding: 20px;
  box-sizing: border-box;
  transition: all .2s ease-in-out;
}
.QuestionTabConfirmPopupContent p{
  font-size: larger;
  font-weight: 500;
}
.QuestionTabConfirmPopupButtons{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
}
.QuestionTabConfirmPopupButtons button{
  background-color: #0077FF;
  color: #fff;
  border: none;
  padding: 12px 30px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 26px;
  transition: all .2s ease-in-out;
  margin-top: 20px;
  width: 48%;
  text-align: center;
}
.QuestionTabConfirmPopupButtons button:hover {
  background-color: #F6511A;
  color: #fff;
}

.OutroStepContainer{
  display: flex;
  flex-direction: column;
  align-items: left;
  background-color: #fffaf4;
  color: #353535;
  border-radius: 20px;
  width: 100%;
  max-width: 800px;
  max-height: 96vh;
  padding: 20px;
  transition: all .2s ease-in-out;
  overflow-y: scroll;
}
.OutroTabNavButtons{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
}
.OutroTabNavButtons button{
  background-color: #0077FF;
  color: #fff;
  border: none;
  padding: 12px 30px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 26px;
  transition: all .2s ease-in-out;
  margin-top: 20px;
  width: 46%;
  text-align: center;
}
.OutroTabNavButtons button:hover {
  background-color: #F6511A;
  color: #fff;
}

.OutroQuestion{
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  text-align: left;
  padding: 10px;
  /* border-top: 2px solid #bdbdbd4b; */
  border-bottom: 2px solid #bdbdbd4b;
  height: fit-content;
}
.OutroQuestionIconContainer{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  color: #fff;
  font-weight: 600;
  margin-right: 20;
}
.OutroQuestionIconContainer h3{
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  padding: 20px;
}
.OutroQuestionIconContainerCorrect h3{
  background-color: #0077FF;
}
.OutroQuestionIconContainerIncorrect h3{
  background-color: #BD081C;
}
.OutroQuestionTextContainer{
  margin-left: 20px;
}
.OutroQuestionTextContainer h6{
  margin-bottom: 6px;
}

.LeaderboardTableContainer{
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 80vh;
  margin-top: 20px;
  overflow-y: scroll;
  border: 1px solid #0077FF;
  border-radius: 20px;
}
.LeaderboardTable{
  border-collapse: collapse;
}
#LeaderboardTableHighlightRow{
  color: #F6511A;
  font-weight: 600;
}
.LeaderboardTable td, .LeaderboardTable th {
  border: 1px solid #0077FF;
  padding: 2px;
  font-weight: 500;
}
.LeaderboardTable th{
  font-size: large;
  position: sticky;
  top: 0;
  background-color: #0077FF; 
  z-index: 1;
  color: #fff;
}


.FlagSelector{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  margin-top: 0;
  margin-bottom: 20px;
  gap: 20px;
  position: sticky;
  top: 100px;
  z-index: 999;
}
.FlagSelectorContainer{
  background-color: #F6F3F3;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 20px;
  padding: 20px 10px 20px 20px;
  border-radius: 0 20px 20px 0;
  border: 3px solid #FFF;
  border-left: none;
}
.FlagSelectorContainer img{
  transition: all .2s ease-in-out;
  width: 55px;
  cursor: pointer;
}

.FlagSelectorContainer img:hover{
  transform: scale(1.1);
}
.FlagSelected{
  padding: 6px 0 6px 0;
  border-top: 3px solid #F6511A;
  border-bottom: 3px solid #F6511A;
}

.custom_flex {
  display: flex;
  justify-content: center;
  gap: 5px;
}
.custom_flex button {
  cursor: pointer;
  border: 1px solid #F6511D;
  border-radius: 25px;
  color: #000;
  font-size: 18px;
  font-weight: 500;
  padding: 10px 30px;
  transition: all .3s ease-in-out;
  min-width: 350px;
  min-height: 50px;
}

.mb-30 {
  margin-bottom: 30px;
}

.logo-filter {
  display: flex;
  justify-content: center;
  align-items: center;
  gap:5px
}

.logo-filter button {
  align-items: center;
  display: flex;
  gap: 5px;
}
.logo-filter button img{
  width: 80px;
  height: 60px;
}

@media (max-width: 768px) {
  .custom_flex {
  display: block !important;
  }
  .custom_flex button {
    font-size: 14px !important;
    margin-bottom: 5px;
  }
  .logo-filter {
    display: flex !important;
    justify-content: center;
    align-items: center;
  }
  .logo-filter button {
    align-items: center;
    display: inline-block;
    margin: 5px;
  }
  .logo-filter button img{
    width: 40px;
    height: 30px;
  }
  
}

.SuggestionsTab{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  font-size: 18px;
  width: 100%;
  height: calc(100% - 153px);
}
.SuggestionsContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 700px;
  height: calc(100vh - 400px);
  padding: 20px;
  color: #353535;
  scrollbar-width: thick;
  scrollbar-color: #353535 #000000;
  border-radius: 20px 0px 20px 20px;
  background-color: #fff;
  overflow-y: scroll;
  min-height: 250px;
}

.SuggestionsTabContainerItem{
  color: #353535;
  padding: 20px;
  border-bottom: 1px solid #BDBDBD;
}
.SuggestionsTabContainerItem img{
  width: 100%;
  max-width: 500px;
}