.countdown-timer {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Mulish", sans-serif;
}

.flap {
    position: relative;
    width: 60px;
    height: 100px;
    margin: 0 1rem;
    display: flex;
    flex-direction: column; /* Stack number and label vertically */
    align-items: center; /* Center the label */
}

.number {
    width: 100%;
    height: 100%;
    color: white; /* Text color */
    font-size: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.219);
    border-radius: 5px;
    padding: 0.5rem;
    text-shadow:
    -1px -1px 0 #000,  
    1px -1px 0 #000,
    -1px 1px 0 #000,
    1px 1px 0 #000;
    
}

.label {
    margin-top: 5px; /* Space between number and label */
    color: white; /* Label color */
    font-size: 16px; /* Label font size */
    font-weight: bold;
    text-shadow:
    -1px -1px 0 #000,  
    1px -1px 0 #000,
    -1px 1px 0 #000,
    1px 1px 0 #000;
}

.dots {

    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin-bottom: 28px;
    font-size: 40px;
    text-shadow:
    -1px -1px 0 #000,  
    1px -1px 0 #000,
    -1px 1px 0 #000,
    1px 1px 0 #000;
}