@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";


/* X-Large devices (large desktops, less than 1400px) */
@media (max-width: 1399.98px) {  }

/*  Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
  .bannerContentLeft, .CategoryBannerContentLeft , .QuestBannerContentLeft{
    width: calc(100% - 540px);
  }
  .bannerContentRight , .CategoryBannerContentRight,.QuestBannerContentRight{
    width: 540px;
  }
  .CategoryCardContainer {
  }
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) { 
  .banner{
    height: auto;
    margin-top: 60px;
    padding-top: 30px;
  }
  .bannerContentRight {
      width: 480px;
  }
  .bannerButtons{
    justify-content: center;
  }
  .bannerContentLeft h1{
    margin-bottom: 14px;
  }
  .CategoryBannerContent{
    flex-direction: column;
  }
  .CategoryBannerContentLeft{
    text-align: center;
    max-width: 100%;
    width: 100%;
    padding: 0 0 20px 0;
  }
  .CategoryBannerContentRight{
    width: 100%;
    max-width: 800px;
    height: auto;
  }
  .CategoryBannerButtons{
    justify-content: center;
  }
  .WhyChooseUs{
    padding: 16px;
  }
  .WhyChooseUsLeft{
    margin-left: 0;
  }
  .WhyChooseUsRight{
    margin-right: 0;
  }
  .FooterLower{
    flex-wrap: wrap;
  }
  .FooterNewsletterSignup{
    flex-basis: 100%;
    margin-top: 40px;
    width: auto;
  }
  .QuestCard{
    width: calc(50% - 10px);
  }
  .VideoContainer {
    max-width: 800px;
    height: 320px;
  }
  .bannerContent{
    flex-direction: column;
  }
  .bannerContentLeft{
    text-align: center;
    max-width: 100%;
    width: 100%;
    padding: 0 0 20px 0;
  }
  .bannerContentRight{
    width: 100%;
    max-width: 800px;
  }
  .bannerColorBlock{
    display: none;
  }
  .NewsSpacerRight{
    display: none;
  }
  .WhatIsThisQuest{
    flex-direction: column;
    margin: 50px 16px 20px;
  }
  .WhatIsThisQuestLeft,.WhatIsThisQuestRight{
    width: 100%;
  }
  .QuestInfoImageDivContentOuter{
    width: 100%;
    margin: 0;
  }
  .QuestInfoImageDiv{
    /* height: auto; */
  }
  .QuestInfoOverviewItemText p{
    font-size: 20px;
    margin: 0 0 8px 0;
  }
  .QuestRoomContent{
    width: 100%;
  }
  .WhatIsThisCategory{
    gap: 30px;
  }
  .QuestBannerContent{
    flex-direction: column;
    gap: 30px;
    height: auto;
    margin: 0;
  }
  .QuestBannerContentLeft,.QuestBannerContentRight{
    width: 100%;
    margin: 0;
    padding: 0;
    text-align: center;
  }
  .QuestBannerButtons{
    justify-content: center;
  }
  .QuestInfo  hr{
    display: none;
  }
  .QuestBannerContentRight{
    max-height: 320px;
  }
  .QuestBannerContentRight img{
    height: 100%;
    width: 100%;
  }
  .QuestBannerContentLeft p{
    font-size: 18px;
    margin-top: 7px;
  }
  .QuestInfoSeeAll{
    position: static;
  }
  .QuestInfoSeeAllText{
    color:  #F6511D;
  }
  .QuestTimerContainer{
    align-self: center;
  }
  .QuestTab{
    padding: 0;
  }
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) { 
  h1,.h1{font-size: 36px;}
  h2,.h2{font-size: 28px;}
  h3,.h3{font-size: 24px;}
  h4,.h4{font-size: 20px;}
  h5,.h5{font-size: 16px;}
  h6,.h6{font-size: 14px;}
  
  .VideoContainer {
    height: 280px;
  }
  .bannerButtons{
    justify-content: center;
  }
  .CategoryCardContainer{
    grid-template-columns: repeat(2, 1fr);
  }
  .FooterLower{
    flex-direction: column;
    gap: 30px;
  }
  .FooterNewsletterSignup{
    margin-top: 0;
  }
  .ContactUsFormUpper{
    flex-direction: column;
  }
  .ContactUsFormField{
    width: 100%;
  }
  .ContactUsFormField input{
    margin-bottom: 0;
  }
  .WhyChooseUs{
    flex-direction: column-reverse;
  }
  .WhyChooseUsRight,.WhyChooseUsLeft{
    width: 100%;
  }
  .CheckUsOut{
    flex-direction: column;
    padding: 16px;
    gap: 0;
  }
  .CheckUsOutLeftGallery{
    padding: 0 0 30px 0;
  }
  .CheckUsOutLeft,.CheckUsOutRight{
    width: 100%;
  }
  .WhatCustomersSayLower{
    flex-direction: column;
  }
  .WhatCustomersSayLowerText{
    width: 100%;
    margin: 20px 0 0;
  }
  .WhatCustomersSay{
    padding: 16px;
  }
  .ContactUs{
    padding: 16px;
    margin: 0 16px 50px;
    border-radius: 14px;
  }
  .TrustedBy img{
    width: 100%;
  }
  .toggleMenu{
    display: block;
  }
  .NavLinks{
    flex-direction: column;
    position: fixed;
    top: 60px;
    background: rgb(255 255 255 / 70%);
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    padding: 40px;
    backdrop-filter: blur(5px);
    transition: all .3s;
    visibility: hidden;
    gap: 0;
  }
  .menu-open .NavLinks{
    opacity: 1;
    transition: all .3s;
    visibility: visible;
  }
  .NavLinks a{
    font-size: 24px;
    margin: 12px 10px;
    color: #000;
  }
  .ContactUsButtonContainer{
    width: auto;
  }
  .QuestCard{
    width: 100%;
  }
  .QuestCardImage{
    height: 320px;
  }
  .QuestCardTitle{
    margin-top: 120px;
  }
  .QuestCardbtns{
    gap: 10px;
  }
  .QuestCardButton{
    padding: 8px 20px;
  }
  .CreatorsContent{
    padding: 16px;
  }
  .bannerContentLeft p{
    font-size: 16px;
    line-height: 1.4;
  }
  .bannerContentRight, .BannerImage{
    height: 280px;
  }
  .QuestInfoStepsLowerItem {
    flex-basis: calc(50% - 10px);
  }
  .QuestInfoStepsLowerItem img{
    max-width: 90px;
  }
  .QuestInfoStepsLowerItem h3 {
    font-size: 18px;
    margin: 10px 0 4px 0;
  }
  .QuestInfoStepsLowerItem h4{
    font-size: 16px;
  }
  .QuestInfoOverviewItemIconContainer{
    width: 80px;
  }
  .QuestInfoOverviewItemText p{
    font-size: 16px;
    margin: 0 0 6px 0;
  }
  .QuestInfoOverviewItemText{
    padding: 16px 14px;
    border-radius: 0px 20px 20px 20px;
  }
  .NewsBanner{
    flex-direction: column;
    height: 400px;
    padding: 0 16px;
  }
  .NewsBannerLeft{
    width: 100%;
  }
  .LatestPostsContainer{
    margin: 0 0 25px 0;
    border-radius: 30px;
    padding: 20px;
  }
  .NewsBannerRight{
    width: 100%;
  }
  .NewsCard{
    flex-direction: column !important;
    padding: 0 16px;
  }
  .NewsCardLeft{
    width: 100%;
    margin: 0;
    padding: 20px;
  }
  .NewsCardRight{
    width: 100%;
    margin: 0;
    padding: 0;
  }
  .NewsCardLeft img{
    width: 100%;
    height: 100%;
    transform: translate(0%, 0%);
    object-fit: cover;
    max-width: 300px;
  }
  .NewsCardRight p{
    padding: 0;
    font-size: 16px;
  }
  .NewsCardRight > p{
    padding: 20px;
  }
  .NewsCardContainer{
    margin-bottom: 80px;
  }
  .QRCode{
    border: 4px solid #FFF;
    border-radius: 12px;
    width: 120px;
    height: 120px;
  }
  .RulesAndBriefing{
    padding: 16px;
    margin: -16px;
  }
  .QuestRoomContent{
    padding: 16px;
  }
  .BookingConfirmation{
     margin: 0;
  }
  .QuestTabAudioPlayer{
    width: 100%;
  }
  .rhap_time{
    font-size: 10px;
  }
  .QuestTabAudioPlayer{
    font-weight: 600;
    border-radius: 10px;
  }
  .rhap_container{
    padding: 8px 10px;
  }
  .EvidenceTabContent{
    height: calc(100vh - 265px);
    margin-top: 5px;
    border-radius: 10px;
    padding: 12px;
  }
  .HintsTabHintsContainer{
    height: calc(100vh - 265px);
    border-radius: 10px;
    padding: 12px;
    min-height: 250px;
  }
  .HintsTab{
    margin: 0;
  }
  .HistoryTabEventContainer{
    border-radius: 10px;
    padding: 12px;
  }
  .QuestionTabContent{
    border-radius: 10px;
    padding: 12px;
  }
  .FooterNavItem{
    font-size: 10px;
  }
  .FooterNavItem:hover{
    border-bottom: 0;
  }
  .QuestRoomContent{
    border: 0;
    border-radius: 0;
    padding: 12px;
  }
  .QuestTabAnswer{
    margin: 0 0 5px;
  }
  .EvidenceTabItem{
    padding: 0 12px;
  }
  .mobile-links{
    display: block !important;
  }
  .NavGamesDrop{
    display: none !important;
  }
  .WhatIsThisCategory{
    flex-direction: column;
  }
  .WhatIsThisCategoryLeft{
    width: 100%;
    margin-left: 0;
    max-width: 400px;
    height: 240px;
  }
  .WhatIsThisCategoryRight{
    width: 100%;
    text-align: center;
  }
  .WhatIsThisCategoryRight hr{
    display: none;
  }
  .WhatIsThisCategoryLeftColorBlock{
    transform: translate(-4%, -8%);
  }
  .QuestBannerContentRight{
    max-height: 240px;
  }
  .QuestRoomContent p{
    font-size: 14px;
  }
  .QuestTabText{
    padding: 12px;
    border-radius: 10px;
    height: calc(100vh - 370px);
  }
  .NewsItemContent p{
    font-size: 14px;
    text-align: center;
  }
  .NewsItem{
    padding: 16px;
  }
  .QuestInfoImageDivContentOuter button{
    margin: 20px auto 0;
  }
  .QuestInfoImageDivContentInner{
    font-size: 14px;
  }
  .QuestInfoStepsUpper h1{
    margin-bottom: 30px;
  }
  .QuestInfoSteps{
    padding: 20px 16px;
  }
  .FooterNav{
    height: 40px;
  }
  .FooterNavItem{
    height: 40px;
  }
  .QuestTabAudioPlayer{
    bottom: 50px;
  }
}

/*  X-Small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) { 
  .CategoryCardContainer{
    grid-template-columns: repeat(1, 1fr);
  }
  h1,.h1{font-size: 28px;}
  h2,.h2{font-size: 24px;}
  h3,.h3{font-size: 20px;}
  h4,.h4{font-size: 16px;}
  h5,.h5{font-size: 14px;}
  h6,.h6{font-size: 12px;}
}